import React from 'react'

const Dashboard = () => {
  return (
    <>
      <h3 style={{ display: "flex", justifyContent: "center", alignItems: "center", margin: "0 auto", }}>
        Welcome to SKYLARK Admin Panel !!!!
      </h3>

    </>
  )
}

export default Dashboard